export default {
  AI: {
    INDEX: {
      PATH: '/b/:businessSlug/ai',
      NAME: 'ai.index',
    },
    SETTINGS: {
      INDEX: {
        PATH: '/b/:businessSlug/ai/settings',
        NAME: 'settings.ai.settings',
      },
    },
  },
}
