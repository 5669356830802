import '@fontsource/nunito/400.css'
import '@fontsource/nunito/600.css'
import '@fontsource/nunito/700.css'
import '@fontsource/nunito/800.css'

import '@/assets/scss/app.scss'
import '@/assets/less/antd.less'

// eslint-disable-next-line no-restricted-imports
import '../node_modules/@syncfusion/ej2-base/styles/bootstrap5.css'
// eslint-disable-next-line no-restricted-imports
import '../node_modules/@syncfusion/ej2-vue-buttons/styles/bootstrap5.css'

import { createApp } from 'vue'
import meta from '@/meta'
import router from '@/routes'
import store from '@/store'
import appI18n from '@/i18n'
import App from '@/App.vue'
import BaseComponents from '@/components/BaseComponents'
import * as Sentry from '@sentry/vue'
import { BuildVersion } from '@/shared'
import { registerLicense } from '@syncfusion/ej2-base'
import { isAxiosError } from 'axios'
;(async () => {
  registerLicense(import.meta.env.VITE_SYNCFUSION_LICENSE)
  const app = createApp(App)

  if (import.meta.env.VITE_BUILD_VERSION !== BuildVersion.Local) {
    Sentry.init({
      app,
      dsn: import.meta.env.VITE_SENTRY_DSN,
      integrations: [
        Sentry.browserTracingIntegration({ router }),
        Sentry.replayIntegration({ maskAllText: false, blockAllMedia: false }),
      ],
      replaysSessionSampleRate: 0,
      replaysOnErrorSampleRate:
        import.meta.env.VITE_BUILD_VERSION === BuildVersion.Production ? 0.5 : 1.0,
      beforeSend(event, hint) {
        const error = hint.originalException

        // Ignore 401 errors
        if (isAxiosError(error) && error.response?.status === 401) {
          return null
        }

        return event
      },
      ignoreErrors: [
        'ResizeObserver loop limit exceeded',
        // Webkit issue in safari extension. Can be safely ignored
        'webkit-masked-url://hidden/',
        // PSPDFKit errors that appear after every deployment. We can't control them
        // but we can ignore them.
        'There was an error loading the image: undefined',
        'Selection.getRangeAt: 0 is out of range',
        'Assertion failed: Cannot find page for widgetAnnotation with id',
        'Assertion failed: Selected start container is neither DOM Element nor Text',
        'Assertion failed: Annotation is unknown',
        'Assertion failed: Condition not met',
        "TypeError: undefined not callable(property 'buttonImportIcon' of[object Object])",
        'Aborted(). Build with -sASSERTIONS for more info.',
        'The index is not in the allowed range.',
        'The image could not be decoded: Loading error.',
      ],
    })
  }

  app.use(store) // must come first in chain to get locale and auth states
  app.use(router).use(appI18n.instance)
  await appI18n.isReady()
  app.use(meta)
  app.use(BaseComponents)
  app.mount('#app')
})()
