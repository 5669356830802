import type { RouteRecordRaw } from 'vue-router'
import { MenuCode } from '@/shared'
import { FeatureEnum } from '@/enums/FeatureEnum'
import { AbilityEnum } from '@/enums/AbilityEnum'
import { RouteList } from '@/routes/RouteList'

const NavBar = () => import('@/components/Layout/AuthenticatedNavBar.vue')
const SubSideBar = () => import('@/components/Layout/SubSideBar.vue')
const DashboardMain = () => import('@/components/Dashboard/DashboardMain.vue')

const Business = {
  Index: () => import('@/views/Settings/Business/BusinessIndexView.vue'),
  Profile: () => import('@/views/Settings/Business/BusinessProfileView.vue'),
  Schedulers: () => import('@/views/Settings/Business/BusinessSchedulersView.vue'),
  Communications: () => import('@/views/Settings/Business/BusinessCommunicationsView.vue'),
  Classrooms: () => import('@/views/Settings/Business/BusinessClassroomsView.vue'),
  Attendance: () => import('@/views/Settings/Business/BusinessAttendanceView.vue'),
}
const LocationList = () => import('@/views/Settings/Locations/LocationListView.vue')
const Location = {
  Index: () => import('@/views/Settings/Locations/LocationIndexView.vue'),
  Profile: () => import('@/views/Settings/Locations/LocationProfileView.vue'),
  Schedulers: () => import('@/views/Settings/Locations/LocationSchedulersView.vue'),
  Communications: () => import('@/views/Settings/Locations/LocationCommunicationsView.vue'),
  Classrooms: () => import('@/views/Settings/Locations/LocationClassroomsView.vue'),
  Attendance: () => import('@/views/Settings/Locations/LocationAttendanceView.vue'),
}
const BusinessDataFieldList = () => import('@/components/Settings/Business/DataFieldList.vue')
const UserList = () => import('@/views/Settings/Users/UsersView.vue')
const RoleList = () => import('@/views/Settings/Roles/RoleView.vue')
const InvitationList = () => import('@/views/Settings/Users/InvitationListView.vue')
const LmsStatusList = () => import('@/views/Settings/Lms/StatusView.vue')
const EmsStatusList = () => import('@/views/Settings/Ems/StatusView.vue')
const ChildStatusList = () => import('@/views/Settings/Lms/ChildStatusView.vue')
const LmsDataField = {
  Index: () => import('@/views/Settings/Lms/DataFieldView.vue'),
  Leads: () => import('@/components/Settings/Lms/DataField/Subpages/DataFieldLeads.vue'),
  Contacts: () => import('@/components/Settings/Lms/DataField/Subpages/DataFieldContacts.vue'),
  Children: () => import('@/components/Settings/Lms/DataField/Subpages/DataFieldChildren.vue'),
  Tracking: () => import('@/components/Settings/Lms/DataField/Subpages/DataFieldTracking.vue'),
}
const EmsDataField = {
  Index: () => import('@/views/Settings/Ems/DataFieldView.vue'),
  Leads: () => import('@/components/Settings/Ems/DataField/Subpages/DataFieldLeads.vue'),
  Contacts: () => import('@/components/Settings/Ems/DataField/Subpages/DataFieldContacts.vue'),
  Children: () => import('@/components/Settings/Ems/DataField/Subpages/DataFieldChildren.vue'),
  Tracking: () => import('@/components/Settings/Ems/DataField/Subpages/DataFieldTracking.vue'),
}
const EmailThemeList = () => import('@/views/Settings/Email/EmailThemeView.vue')
const EmailDesignList = () => import('@/views/Settings/Email/EmailDesignView.vue')
const EmailThemeConfig = () => import('@/views/Settings/Email/EmailThemeConfigView.vue')
const SettingsPhoneNumbers = {
  Index: () => import('@/views/Settings/PhoneNumber/PhoneNumbersIndexView.vue'),
  PhoneNumbers: () => import('@/views/Settings/PhoneNumber/PhoneNumberView.vue'),
  Compliance: () => import('@/views/Settings/PhoneNumber/ComplianceView.vue'),
}
const SearchNumbers = () => import('@/views/Settings/PhoneNumber/SearchNumbers.vue')
const ComplianceApplication = () => import('@/views/Settings/PhoneNumber/ComplianceApplication.vue')
const CustomDomains = {
  Index: () => import('@/views/Settings/CustomDomains/CustomDomainsView.vue'),
  Profile: () => import('@/views/Settings/CustomDomains/CustomDomainsProfileView.vue'),
}

const Merchant = {
  MerchantTransactionShow: () =>
    import('@/components/Settings/Merchant/Transaction/TransactionShow.vue'),
  MerchantPayouts: () => import('@/components/Settings/Merchant/Payouts/PayoutList.vue'),
  MerchantPayoutShow: () => import('@/components/Settings/Merchant/Payouts/PayoutShow.vue'),
  MerchantComplianceFormShow: () =>
    import('@/components/Settings/Merchant/ComplianceForms/ComplianceForm.vue'),
  MerchantTransactionList: () =>
    import('@/components/Settings/Merchant/Transaction/TransactionList.vue'),
  MerchantProfile: () => import('@/components/Settings/Merchant/MerchantProfile.vue'),
  MerchantForm: () => import('@/components/Settings/Merchant/MerchantForm.vue'),
  MerchantReports: () => import('@/views/Settings/Merchant/MerchantReportsView.vue'),
}

const UserSingle = () => import('@/views/Settings/Users/UserSingleView.vue')
const CompanyList = () => import('@/components/Settings/Company/CompanyList.vue')
const aiSettings = () => import('@/views/Settings/AI/AiSettingsIndexView.vue')
const aiSettingsFeatures = () => import('@/views/Settings/AI/AiSettingsFeaturesView.vue')
const aiSettingsConfiguration = () => import('@/views/Settings/AI/AiSettingsConfigurationView.vue')
const Integrations = {
  Index: () => import('@/views/Integrations/BusinessIntegrationsView.vue'),
  Google: () =>
    import('@/views/Integrations/BusinessIntegrationSetup/BusinessIntegrationSetupGoogleView.vue'),
  Microsoft: () =>
    import(
      '@/views/Integrations/BusinessIntegrationSetup/BusinessIntegrationSetupMicrosoftView.vue'
    ),
  Zoom: () =>
    import('@/views/Integrations/BusinessIntegrationSetup/BusinessIntegrationSetupZoomView.vue'),
  Facebook: () =>
    import(
      '@/views/Integrations/BusinessIntegrationSetup/BusinessIntegrationSetupFacebookView.vue'
    ),
  ProcareOnline: () =>
    import(
      '@/views/Integrations/BusinessIntegrationSetup/BusinessIntegrationSetupProcareOnlineView.vue'
    ),
  Procare: () =>
    import('@/views/Integrations/BusinessIntegrationSetup/BusinessIntegrationSetupProcareView.vue'),
  // TODO - implement Kangrootime
  Kangarootime: () =>
    import(
      '@/views/Integrations/BusinessIntegrationSetup/BusinessIntegrationSetupKangarootimeView.vue'
    ),
  Famly: () =>
    import('@/views/Integrations/BusinessIntegrationSetup/BusinessIntegrationSetupFamlyView.vue'),
  API: () =>
    import('@/views/Integrations/BusinessIntegrationSetup/BusinessIntegrationSetupAPI.vue'),
}

export default <RouteRecordRaw[]>[
  {
    path: RouteList.SETTINGS.BUSINESS.INDEX.PATH,
    name: RouteList.SETTINGS.BUSINESS.INDEX.NAME,
    components: {
      default: DashboardMain,
      SideBar: SubSideBar,
      NavBar,
    },
    props: {
      SideBar: {
        menuCode: MenuCode.Settings,
        title: 'Settings',
      },
    },
    meta: {
      requiresAuth: true,
      requiresSubscription: true,
      feature: FeatureEnum.SETTINGS_BUSINESS,
      permission: AbilityEnum.SETTING_BUSINESS,
    },
    children: [
      {
        path: RouteList.SETTINGS.BUSINESS.BUSINESS.INDEX.PATH,
        name: RouteList.SETTINGS.BUSINESS.BUSINESS.INDEX.NAME,
        component: Business.Index,
        meta: {
          feature: [FeatureEnum.SETTINGS_BUSINESS_BUSINESS, FeatureEnum.SETTINGS_BUSINESS],
          permission: [AbilityEnum.SETTING_BUSINESS_BUSINESS, AbilityEnum.SETTING_BUSINESS],
        },
        redirect: {
          name: RouteList.SETTINGS.BUSINESS.BUSINESS.PROFILE.NAME,
        },
        children: [
          {
            path: RouteList.SETTINGS.BUSINESS.BUSINESS.PROFILE.PATH,
            name: RouteList.SETTINGS.BUSINESS.BUSINESS.PROFILE.NAME,
            component: Business.Profile,
            meta: {
              feature: [FeatureEnum.SETTINGS_BUSINESS_BUSINESS, FeatureEnum.SETTINGS_BUSINESS],
              permission: [AbilityEnum.SETTING_BUSINESS_BUSINESS, AbilityEnum.SETTING_BUSINESS],
            },
          },
          {
            path: RouteList.SETTINGS.BUSINESS.BUSINESS.SCHEDULERS.PATH,
            name: RouteList.SETTINGS.BUSINESS.BUSINESS.SCHEDULERS.NAME,
            component: Business.Schedulers,
            meta: {
              feature: [
                FeatureEnum.SETTINGS_BUSINESS_DATA_FIELD,
                FeatureEnum.SETTINGS_BUSINESS_BUSINESS,
                FeatureEnum.SETTINGS_BUSINESS,
              ],
              permission: [
                AbilityEnum.SETTING_BUSINESS_DATA_FIELD,
                AbilityEnum.SETTING_BUSINESS_BUSINESS,
                AbilityEnum.SETTING_BUSINESS,
              ],
            },
          },
          {
            path: RouteList.SETTINGS.BUSINESS.BUSINESS.COMMUNICATIONS.PATH,
            name: RouteList.SETTINGS.BUSINESS.BUSINESS.COMMUNICATIONS.NAME,
            component: Business.Communications,
            meta: {
              feature: [FeatureEnum.SETTINGS_BUSINESS_BUSINESS, FeatureEnum.SETTINGS_BUSINESS],
              permission: [
                AbilityEnum.SETTING_BUSINESS_CONVERSATIONS_SETTINGS,
                AbilityEnum.SETTING_BUSINESS_BUSINESS,
                AbilityEnum.SETTING_BUSINESS,
              ],
            },
          },
          {
            path: RouteList.SETTINGS.BUSINESS.BUSINESS.CLASSROOMS.PATH,
            name: RouteList.SETTINGS.BUSINESS.BUSINESS.CLASSROOMS.NAME,
            component: Business.Classrooms,
            meta: {
              feature: [FeatureEnum.SETTINGS_BUSINESS_BUSINESS, FeatureEnum.SETTINGS_BUSINESS],
              permission: [AbilityEnum.SETTING_BUSINESS_BUSINESS, AbilityEnum.SETTING_BUSINESS],
            },
          },
          {
            path: RouteList.SETTINGS.BUSINESS.BUSINESS.ATTENDANCE.PATH,
            name: RouteList.SETTINGS.BUSINESS.BUSINESS.ATTENDANCE.NAME,
            component: Business.Attendance,
            meta: {
              feature: [FeatureEnum.SETTINGS_BUSINESS_BUSINESS, FeatureEnum.SETTINGS_BUSINESS],
              permission: [AbilityEnum.SETTING_BUSINESS_BUSINESS, AbilityEnum.SETTING_BUSINESS],
            },
          },
        ],
      },
      {
        path: RouteList.SETTINGS.BUSINESS.LOCATIONS.INDEX.PATH,
        name: RouteList.SETTINGS.BUSINESS.LOCATIONS.INDEX.NAME,
        component: LocationList,
        meta: {
          feature: [FeatureEnum.SETTINGS_BUSINESS_LOCATION, FeatureEnum.SETTINGS_BUSINESS],
          permission: [AbilityEnum.SETTING_BUSINESS_LOCATION, AbilityEnum.SETTING_BUSINESS],
        },
      },
      {
        path: RouteList.SETTINGS.BUSINESS.LOCATIONS.LOCATION.INDEX.PATH,
        name: RouteList.SETTINGS.BUSINESS.LOCATIONS.LOCATION.INDEX.NAME,
        component: Location.Index,
        meta: {
          feature: [FeatureEnum.SETTINGS_BUSINESS_LOCATION, FeatureEnum.SETTINGS_BUSINESS],
          permission: [
            AbilityEnum.SETTING_LOCATION_VIEW,
            AbilityEnum.SETTING_BUSINESS_LOCATION,
            AbilityEnum.SETTING_BUSINESS,
          ],
        },
        redirect: {
          name: RouteList.SETTINGS.BUSINESS.LOCATIONS.LOCATION.PROFILE.NAME,
        },
        children: [
          {
            path: RouteList.SETTINGS.BUSINESS.LOCATIONS.LOCATION.PROFILE.PATH,
            name: RouteList.SETTINGS.BUSINESS.LOCATIONS.LOCATION.PROFILE.NAME,
            component: Location.Profile,
            meta: {
              title: 'Location Profile',
              feature: [FeatureEnum.SETTINGS_BUSINESS_LOCATION, FeatureEnum.SETTINGS_BUSINESS],
              permission: [
                AbilityEnum.SETTING_LOCATION_VIEW,
                AbilityEnum.SETTING_BUSINESS_LOCATION,
                AbilityEnum.SETTING_BUSINESS,
              ],
            },
          },
          {
            path: RouteList.SETTINGS.BUSINESS.LOCATIONS.LOCATION.ATTENDANCE.PATH,
            name: RouteList.SETTINGS.BUSINESS.LOCATIONS.LOCATION.ATTENDANCE.NAME,
            component: Location.Attendance,
            meta: {
              title: 'Location Attendance',
              feature: [FeatureEnum.SETTINGS_BUSINESS_LOCATION, FeatureEnum.SETTINGS_BUSINESS],
              permission: [
                AbilityEnum.SETTING_LOCATION_EDIT,
                AbilityEnum.SETTING_LOCATION_VIEW,
                AbilityEnum.SETTING_LOCATION_ATTENDANCE_VIEW,
                AbilityEnum.SETTING_BUSINESS_LOCATION,
                AbilityEnum.SETTING_BUSINESS,
              ],
            },
          },
          {
            path: RouteList.SETTINGS.BUSINESS.LOCATIONS.LOCATION.SCHEDULERS.PATH,
            name: RouteList.SETTINGS.BUSINESS.LOCATIONS.LOCATION.SCHEDULERS.NAME,
            component: Location.Schedulers,
            meta: {
              title: 'Location Schedulers',
              feature: [FeatureEnum.SETTINGS_BUSINESS_LOCATION, FeatureEnum.SETTINGS_BUSINESS],
              permission: [
                AbilityEnum.SETTING_LOCATION_SCHEDULERS_VIEW,
                AbilityEnum.SETTING_LOCATION_VIEW,
                AbilityEnum.SETTING_BUSINESS_LOCATION,
                AbilityEnum.SETTING_BUSINESS,
              ],
            },
          },
          {
            path: RouteList.SETTINGS.BUSINESS.LOCATIONS.LOCATION.COMMUNICATIONS.PATH,
            name: RouteList.SETTINGS.BUSINESS.LOCATIONS.LOCATION.COMMUNICATIONS.NAME,
            component: Location.Communications,
            meta: {
              title: 'Location Communications',
              feature: [FeatureEnum.SETTINGS_BUSINESS_LOCATION, FeatureEnum.SETTINGS_BUSINESS],
              permission: [
                AbilityEnum.SETTING_LOCATION_CONVERSATIONS_SETTINGS,
                AbilityEnum.SETTING_LOCATION_VIEW,
                AbilityEnum.SETTING_BUSINESS_LOCATION,
                AbilityEnum.SETTING_BUSINESS,
              ],
            },
          },
          {
            path: RouteList.SETTINGS.BUSINESS.LOCATIONS.LOCATION.CLASSROOMS.PATH,
            name: RouteList.SETTINGS.BUSINESS.LOCATIONS.LOCATION.CLASSROOMS.NAME,
            component: Location.Classrooms,
            meta: {
              title: 'Location Classrooms',
              feature: [FeatureEnum.SETTINGS_BUSINESS_LOCATION, FeatureEnum.SETTINGS_BUSINESS],
              permission: [
                AbilityEnum.SETTING_LOCATION_CLASSROOMS_VIEW,
                AbilityEnum.SETTING_LOCATION_CLASSROOMS,
                AbilityEnum.SETTING_BUSINESS_LOCATION,
                AbilityEnum.SETTING_BUSINESS,
              ],
            },
          },
        ],
      },
      {
        path: RouteList.SETTINGS.BUSINESS.DATA_FIELDS.PATH,
        name: RouteList.SETTINGS.BUSINESS.DATA_FIELDS.NAME,
        component: BusinessDataFieldList,
        meta: {
          feature: [
            FeatureEnum.SETTINGS_BUSINESS_DATA_FIELD,
            FeatureEnum.SETTINGS_BUSINESS_BUSINESS,
            FeatureEnum.SETTINGS_BUSINESS,
          ],
          permission: [AbilityEnum.SETTING_BUSINESS_DATA_FIELD, AbilityEnum.SETTING_BUSINESS],
        },
      },
      {
        path: RouteList.SETTINGS.BUSINESS.INTEGRATIONS.INDEX.PATH,
        name: RouteList.SETTINGS.BUSINESS.INTEGRATIONS.INDEX.NAME,
        component: Integrations.Index,
        meta: {
          feature: [FeatureEnum.SETTINGS_BUSINESS_INTEGRATION, FeatureEnum.SETTINGS_BUSINESS],
        },
      },
      {
        path: RouteList.SETTINGS.BUSINESS.INTEGRATIONS.GOOGLE.PATH,
        name: RouteList.SETTINGS.BUSINESS.INTEGRATIONS.GOOGLE.NAME,
        component: Integrations.Google,
        meta: {
          feature: [
            FeatureEnum.INTEGRATIONS_GOOGLE,
            FeatureEnum.SETTINGS_BUSINESS_INTEGRATION,
            FeatureEnum.SETTINGS_BUSINESS,
          ],
        },
      },
      {
        path: RouteList.SETTINGS.BUSINESS.INTEGRATIONS.ZOOM.PATH,
        name: RouteList.SETTINGS.BUSINESS.INTEGRATIONS.ZOOM.NAME,
        component: Integrations.Zoom,
        meta: {
          feature: [
            FeatureEnum.INTEGRATIONS_ZOOM,
            FeatureEnum.SETTINGS_BUSINESS_INTEGRATION,
            FeatureEnum.SETTINGS_BUSINESS,
          ],
        },
      },
      {
        path: RouteList.SETTINGS.BUSINESS.INTEGRATIONS.FACEBOOK.PATH,
        name: RouteList.SETTINGS.BUSINESS.INTEGRATIONS.FACEBOOK.NAME,
        component: Integrations.Facebook,
        meta: {
          feature: [
            FeatureEnum.INTEGRATIONS_FACEBOOK,
            FeatureEnum.SETTINGS_BUSINESS_INTEGRATION,
            FeatureEnum.SETTINGS_BUSINESS,
          ],
        },
      },
      {
        path: RouteList.SETTINGS.BUSINESS.INTEGRATIONS.MICROSOFT.PATH,
        name: RouteList.SETTINGS.BUSINESS.INTEGRATIONS.MICROSOFT.NAME,
        component: Integrations.Microsoft,
        meta: {
          feature: [
            FeatureEnum.INTEGRATIONS_MICROSOFT,
            FeatureEnum.SETTINGS_BUSINESS_INTEGRATION,
            FeatureEnum.SETTINGS_BUSINESS,
          ],
        },
      },
      {
        path: RouteList.SETTINGS.BUSINESS.INTEGRATIONS.PROCARE_ONLINE.PATH,
        name: RouteList.SETTINGS.BUSINESS.INTEGRATIONS.PROCARE_ONLINE.NAME,
        component: Integrations.ProcareOnline,
        meta: {
          feature: [
            FeatureEnum.INTEGRATIONS_PROCARE_ONLINE,
            FeatureEnum.SETTINGS_BUSINESS_INTEGRATION,
            FeatureEnum.SETTINGS_BUSINESS,
          ],
        },
      },
      {
        path: RouteList.SETTINGS.BUSINESS.INTEGRATIONS.PROCARE.PATH,
        name: RouteList.SETTINGS.BUSINESS.INTEGRATIONS.PROCARE.NAME,
        component: Integrations.Procare,
        meta: {
          feature: [
            FeatureEnum.INTEGRATIONS_PROCARE_DESKTOP,
            FeatureEnum.SETTINGS_BUSINESS_INTEGRATION,
            FeatureEnum.SETTINGS_BUSINESS,
          ],
        },
      },
      {
        path: RouteList.SETTINGS.BUSINESS.INTEGRATIONS.KANGAROOTIME.PATH,
        name: RouteList.SETTINGS.BUSINESS.INTEGRATIONS.KANGAROOTIME.NAME,
        component: Integrations.Kangarootime,
        meta: {
          feature: [
            FeatureEnum.INTEGRATIONS_KANGAROOTIME,
            FeatureEnum.SETTINGS_BUSINESS_INTEGRATION,
            FeatureEnum.SETTINGS_BUSINESS,
          ],
        },
      },
      {
        path: RouteList.SETTINGS.BUSINESS.INTEGRATIONS.FAMLY.PATH,
        name: RouteList.SETTINGS.BUSINESS.INTEGRATIONS.FAMLY.NAME,
        component: Integrations.Famly,
        meta: {
          feature: [
            FeatureEnum.INTEGRATIONS_FAMLY,
            FeatureEnum.SETTINGS_BUSINESS_INTEGRATION,
            FeatureEnum.SETTINGS_BUSINESS,
          ],
        },
      },
      {
        path: RouteList.SETTINGS.BUSINESS.INTEGRATIONS.CARECOM.PATH,
        name: RouteList.SETTINGS.BUSINESS.INTEGRATIONS.CARECOM.NAME,
        component: Integrations.API,
        meta: {
          feature: [
            FeatureEnum.INTEGRATIONS_CARECOM,
            FeatureEnum.SETTINGS_BUSINESS_INTEGRATION,
            FeatureEnum.SETTINGS_BUSINESS,
          ],
        },
      },
      {
        path: RouteList.SETTINGS.BUSINESS.INTEGRATIONS.WINNIE.PATH,
        name: RouteList.SETTINGS.BUSINESS.INTEGRATIONS.WINNIE.NAME,
        component: Integrations.API,
        meta: {
          feature: [
            FeatureEnum.INTEGRATIONS_WINNIE,
            FeatureEnum.SETTINGS_BUSINESS_INTEGRATION,
            FeatureEnum.SETTINGS_BUSINESS,
          ],
        },
      },
      {
        path: RouteList.SETTINGS.BUSINESS.INTEGRATIONS.LOOKERSTUDIO.PATH,
        name: RouteList.SETTINGS.BUSINESS.INTEGRATIONS.LOOKERSTUDIO.NAME,
        component: Integrations.API,
        meta: {
          feature: [
            FeatureEnum.INTEGRATIONS_LOOKERSTUDIO,
            FeatureEnum.SETTINGS_BUSINESS_INTEGRATION,
            FeatureEnum.SETTINGS_BUSINESS,
          ],
        },
      },
      {
        path: RouteList.SETTINGS.BUSINESS.INTEGRATIONS.APIV2.PATH,
        name: RouteList.SETTINGS.BUSINESS.INTEGRATIONS.APIV2.NAME,
        component: Integrations.API,
        meta: {
          feature: [
            FeatureEnum.INTEGRATIONS_APIV2,
            FeatureEnum.SETTINGS_BUSINESS_INTEGRATION,
            FeatureEnum.SETTINGS_BUSINESS,
          ],
        },
      },
    ],
  },
  {
    path: RouteList.SETTINGS.LMS.INDEX.PATH,
    name: RouteList.SETTINGS.LMS.INDEX.NAME,
    components: {
      default: DashboardMain,
      SideBar: SubSideBar,
      NavBar,
    },
    props: {
      SideBar: {
        menuCode: MenuCode.Settings,
        title: 'Settings',
      },
    },
    meta: {
      requiresAuth: true,
      requiresSubscription: true,
      feature: [FeatureEnum.SETTINGS_LMS, FeatureEnum.LMS],
      permission: [AbilityEnum.SETTING_LMS],
    },
    children: [
      {
        path: RouteList.SETTINGS.LMS.STATUSES.PATH,
        name: RouteList.SETTINGS.LMS.STATUSES.NAME,
        component: LmsStatusList,
        meta: {
          feature: [FeatureEnum.SETTINGS_LMS_STATUS, FeatureEnum.SETTINGS_LMS, FeatureEnum.LMS],
          permission: [AbilityEnum.SETTING_LMS_STATUS, AbilityEnum.SETTING_LMS],
        },
      },
      {
        path: RouteList.SETTINGS.LMS.CHILD_STATUS.PATH,
        name: RouteList.SETTINGS.LMS.CHILD_STATUS.NAME,
        component: ChildStatusList,
        meta: {
          feature: [
            FeatureEnum.SETTINGS_LMS_CHILD_STATUS,
            FeatureEnum.SETTINGS_LMS,
            FeatureEnum.LMS,
          ],
          permission: [AbilityEnum.SETTING_LMS_CHILD_STATUS, AbilityEnum.SETTING_LMS],
        },
      },
      {
        path: RouteList.SETTINGS.LMS.DATA_FIELDS.INDEX.PATH,
        name: RouteList.SETTINGS.LMS.DATA_FIELDS.INDEX.NAME,
        component: LmsDataField.Index,
        meta: {
          feature: [FeatureEnum.SETTINGS_LMS_DATA_FIELD, FeatureEnum.SETTINGS_LMS, FeatureEnum.LMS],
          permission: [AbilityEnum.SETTING_LMS_DATA_FIELD, AbilityEnum.SETTING_LMS],
        },
        redirect: {
          name: RouteList.SETTINGS.LMS.DATA_FIELDS.LEADS.NAME,
        },
        children: [
          {
            path: RouteList.SETTINGS.LMS.DATA_FIELDS.LEADS.PATH,
            name: RouteList.SETTINGS.LMS.DATA_FIELDS.LEADS.NAME,
            component: LmsDataField.Leads,
          },
          {
            path: RouteList.SETTINGS.LMS.DATA_FIELDS.CONTACTS.PATH,
            name: RouteList.SETTINGS.LMS.DATA_FIELDS.CONTACTS.NAME,
            component: LmsDataField.Contacts,
          },
          {
            path: RouteList.SETTINGS.LMS.DATA_FIELDS.CHILDREN.PATH,
            name: RouteList.SETTINGS.LMS.DATA_FIELDS.CHILDREN.NAME,
            component: LmsDataField.Children,
          },
          {
            path: RouteList.SETTINGS.LMS.DATA_FIELDS.TRACKING.PATH,
            name: RouteList.SETTINGS.LMS.DATA_FIELDS.TRACKING.NAME,
            component: LmsDataField.Tracking,
          },
        ],
      },
    ],
  },
  {
    path: RouteList.SETTINGS.EMS.INDEX.PATH,
    name: RouteList.SETTINGS.EMS.INDEX.NAME,
    components: {
      default: DashboardMain,
      SideBar: SubSideBar,
      NavBar,
    },
    props: {
      SideBar: {
        menuCode: MenuCode.Settings,
        title: 'Settings',
      },
    },
    meta: {
      requiresAuth: true,
      requiresSubscription: true,
      feature: [FeatureEnum.SETTINGS_EMS, FeatureEnum.EMS],
      permission: [AbilityEnum.SETTING_EMS],
    },
    children: [
      {
        path: RouteList.SETTINGS.EMS.STATUSES.PATH,
        name: RouteList.SETTINGS.EMS.STATUSES.NAME,
        component: EmsStatusList,
        meta: {
          feature: [FeatureEnum.SETTINGS_EMS_STATUS, FeatureEnum.SETTINGS_EMS, FeatureEnum.EMS],
          permission: [AbilityEnum.SETTING_EMS_STATUS, AbilityEnum.SETTING_EMS],
        },
      },
      {
        path: RouteList.SETTINGS.EMS.DATA_FIELDS.INDEX.PATH,
        name: RouteList.SETTINGS.EMS.DATA_FIELDS.INDEX.NAME,
        component: EmsDataField.Index,
        meta: {
          feature: [FeatureEnum.SETTINGS_EMS_DATA_FIELD, FeatureEnum.SETTINGS_EMS, FeatureEnum.EMS],
          permission: [AbilityEnum.SETTING_EMS_DATA_FIELD, AbilityEnum.SETTING_EMS],
        },
        redirect: {
          name: RouteList.SETTINGS.EMS.DATA_FIELDS.CANDIDATES.NAME,
        },
        children: [
          {
            path: RouteList.SETTINGS.EMS.DATA_FIELDS.CANDIDATES.PATH,
            name: RouteList.SETTINGS.EMS.DATA_FIELDS.CANDIDATES.NAME,
            component: EmsDataField.Leads,
          },
          {
            path: RouteList.SETTINGS.EMS.DATA_FIELDS.CONTACTS.PATH,
            name: RouteList.SETTINGS.EMS.DATA_FIELDS.CONTACTS.NAME,
            component: EmsDataField.Contacts,
          },
          {
            path: RouteList.SETTINGS.EMS.DATA_FIELDS.TRACKING.PATH,
            name: RouteList.SETTINGS.EMS.DATA_FIELDS.TRACKING.NAME,
            component: EmsDataField.Tracking,
          },
        ],
      },
    ],
  },
  {
    path: RouteList.SETTINGS.AI.INDEX.PATH,
    name: RouteList.SETTINGS.AI.INDEX.NAME,
    components: {
      default: DashboardMain,
      SideBar: SubSideBar,
      NavBar,
    },
    props: {
      SideBar: {
        menuCode: MenuCode.Settings,
        title: 'Settings',
      },
    },
    meta: {
      requiresAuth: true,
      requiresSubscription: true,
      feature: [FeatureEnum.SETTINGS_AI, FeatureEnum.SETTINGS_AI_SETTINGS],
      permission: [AbilityEnum.SETTING_AI],
    },
    children: [
      {
        path: RouteList.SETTINGS.AI.SETTINGS.INDEX.PATH,
        name: RouteList.SETTINGS.AI.SETTINGS.INDEX.NAME,
        component: aiSettings,
        meta: {
          permission: [AbilityEnum.SETTING_AI, AbilityEnum.SETTING_AI_SETTINGS],
        },
        redirect: {
          name: RouteList.SETTINGS.AI.SETTINGS.FEATURES.NAME,
        },
        children: [
          {
            path: RouteList.SETTINGS.AI.SETTINGS.FEATURES.PATH,
            name: RouteList.SETTINGS.AI.SETTINGS.FEATURES.NAME,
            component: aiSettingsFeatures,
            meta: {
              permission: [AbilityEnum.SETTING_AI, AbilityEnum.SETTING_AI_SETTINGS],
            },
          },
          {
            path: RouteList.SETTINGS.AI.SETTINGS.CONFIGURATION.PATH,
            name: RouteList.SETTINGS.AI.SETTINGS.CONFIGURATION.NAME,
            component: aiSettingsConfiguration,
            meta: {
              permission: [AbilityEnum.SETTING_AI, AbilityEnum.SETTING_AI_SETTINGS],
            },
          },
        ],
      },
    ],
  },
  {
    path: RouteList.SETTINGS.COMPANY.INDEX.PATH,
    name: RouteList.SETTINGS.COMPANY.INDEX.NAME,
    components: {
      default: DashboardMain,
      SideBar: SubSideBar,
      NavBar,
    },
    props: {
      SideBar: {
        menuCode: MenuCode.Settings,
        title: 'Settings',
      },
    },
    meta: {
      requiresAuth: true,
      requiresSubscription: true,
      feature: [FeatureEnum.SETTINGS_COMPANY, FeatureEnum.SETTINGS_COMPANY_COMPANY],
      permission: AbilityEnum.SETTING_COMPANY,
    },
    children: [
      {
        path: RouteList.SETTINGS.COMPANY.LIST.PATH,
        name: RouteList.SETTINGS.COMPANY.LIST.NAME,
        component: CompanyList,
        meta: {
          permission: [AbilityEnum.SETTING_COMPANY_COMPANY, AbilityEnum.SETTING_COMPANY],
        },
      },
    ],
  },
  {
    path: RouteList.SETTINGS.MERCHANT.INDEX.PATH,
    name: RouteList.SETTINGS.MERCHANT.INDEX.NAME,
    components: {
      default: DashboardMain,
      SideBar: SubSideBar,
      NavBar,
    },
    props: {
      SideBar: {
        menuCode: MenuCode.Settings,
        title: 'Settings',
      },
    },
    meta: {
      requiresAuth: true,
      requiresSubscription: true,
      feature: [FeatureEnum.SETTINGS_COMPANY, FeatureEnum.SETTINGS_COMPANY_MERCHANT],
      permission: AbilityEnum.SETTING_COMPANY_MERCHANT,
    },
    redirect: {
      name: RouteList.SETTINGS.MERCHANT.PROFILE.NAME,
    },
    children: [
      {
        path: RouteList.SETTINGS.MERCHANT.PROFILE.PATH,
        name: RouteList.SETTINGS.MERCHANT.PROFILE.NAME,
        component: Merchant.MerchantProfile,
        meta: {
          permission: [
            AbilityEnum.SETTING_COMPANY_MERCHANT_PROFILE,
            AbilityEnum.SETTING_COMPANY_MERCHANT,
            AbilityEnum.SETTING_COMPANY,
          ],
        },
      },
      {
        path: RouteList.SETTINGS.MERCHANT.PAYOUTS.INDEX.PATH,
        name: RouteList.SETTINGS.MERCHANT.PAYOUTS.INDEX.NAME,
        component: Merchant.MerchantPayouts,
        meta: {
          permission: [
            AbilityEnum.SETTING_COMPANY_MERCHANT_PAYOUT,
            AbilityEnum.SETTING_COMPANY_MERCHANT,
            AbilityEnum.SETTING_COMPANY,
          ],
        },
      },
      {
        path: RouteList.SETTINGS.MERCHANT.PAYOUTS.PAYOUT.PATH,
        name: RouteList.SETTINGS.MERCHANT.PAYOUTS.PAYOUT.NAME,
        component: Merchant.MerchantPayoutShow,
        meta: {
          permission: [
            AbilityEnum.SETTING_COMPANY_MERCHANT_PAYOUT,
            AbilityEnum.SETTING_COMPANY_MERCHANT,
            AbilityEnum.SETTING_COMPANY,
          ],
        },
      },
      {
        path: RouteList.SETTINGS.MERCHANT.TRANSACTIONS.INDEX.PATH,
        name: RouteList.SETTINGS.MERCHANT.TRANSACTIONS.INDEX.NAME,
        component: Merchant.MerchantTransactionList,
        meta: {
          permission: [
            AbilityEnum.SETTING_COMPANY_MERCHANT_TRANSACTION,
            AbilityEnum.SETTING_COMPANY_MERCHANT,
            AbilityEnum.SETTING_COMPANY,
          ],
        },
      },
      {
        path: RouteList.SETTINGS.MERCHANT.TRANSACTIONS.TRANSACTION.PATH,
        name: RouteList.SETTINGS.MERCHANT.TRANSACTIONS.TRANSACTION.NAME,
        component: Merchant.MerchantTransactionShow,
        meta: {
          permission: [
            AbilityEnum.SETTING_COMPANY_MERCHANT_TRANSACTION,
            AbilityEnum.SETTING_COMPANY_MERCHANT,
            AbilityEnum.SETTING_COMPANY,
          ],
        },
      },
      {
        path: RouteList.SETTINGS.MERCHANT.REPORTS.PATH,
        name: RouteList.SETTINGS.MERCHANT.REPORTS.NAME,
        component: Merchant.MerchantReports,
        meta: {
          permission: [
            AbilityEnum.SETTING_COMPANY_MERCHANT_REPORTS,
            AbilityEnum.SETTING_COMPANY_MERCHANT,
            AbilityEnum.SETTING_COMPANY,
          ],
        },
      },
      {
        path: RouteList.SETTINGS.MERCHANT.COMPLIANCE_FORMS.COMPLIANCE_FORM.PATH,
        name: RouteList.SETTINGS.MERCHANT.COMPLIANCE_FORMS.COMPLIANCE_FORM.NAME,
        component: Merchant.MerchantComplianceFormShow,
        meta: {
          permission: [
            AbilityEnum.SETTING_COMPANY_MERCHANT_COMPLIANCE_FORM_PREVIEW,
            AbilityEnum.SETTING_COMPANY_MERCHANT,
            AbilityEnum.SETTING_COMPANY,
          ],
        },
      },
      {
        path: RouteList.SETTINGS.MERCHANT.FORM.PATH,
        name: RouteList.SETTINGS.MERCHANT.FORM.NAME,
        component: Merchant.MerchantForm,
        meta: {
          permission: [AbilityEnum.SETTING_COMPANY_MERCHANT, AbilityEnum.SETTING_COMPANY],
        },
      },
    ],
  },
  {
    path: RouteList.SETTINGS.BRANDING.INDEX.PATH,
    name: RouteList.SETTINGS.BRANDING.INDEX.NAME,
    components: {
      default: DashboardMain,
      SideBar: SubSideBar,
      NavBar,
    },
    props: {
      SideBar: {
        menuCode: MenuCode.Settings,
        title: 'Settings',
      },
    },
    meta: {
      requiresAuth: true,
      requiresSubscription: true,
      feature: FeatureEnum.SETTINGS_BRANDING,
      permission: AbilityEnum.SETTING_BRANDING,
    },
    children: [
      {
        path: RouteList.SETTINGS.BRANDING.EMAIL.INDEX.PATH,
        name: RouteList.SETTINGS.BRANDING.EMAIL.INDEX.NAME,
        components: {
          default: DashboardMain,
          SideBar: SubSideBar,
          NavBar,
        },
        props: {
          SideBar: {
            menuCode: MenuCode.Settings,
            title: 'Settings',
          },
        },
        meta: {
          requiresAuth: true,
          requiresSubscription: true,
          feature: [FeatureEnum.SETTINGS_BRANDING, FeatureEnum.SETTINGS_BRANDING_EMAILS],
          permission: AbilityEnum.SETTING_BRANDING_EMAIL,
        },
        redirect: {
          name: RouteList.SETTINGS.BRANDING.EMAIL.EMAIL_THEMES.NAME,
        },
        children: [
          {
            path: RouteList.SETTINGS.BRANDING.EMAIL.EMAIL_THEMES.PATH,
            name: RouteList.SETTINGS.BRANDING.EMAIL.EMAIL_THEMES.NAME,
            component: EmailThemeList,
            meta: {
              feature: [FeatureEnum.SETTINGS_BRANDING_EMAILS, FeatureEnum.SETTINGS_BRANDING],
              permission: [
                AbilityEnum.SETTING_BRANDING_EMAIL,
                AbilityEnum.SETTING_BRANDING_EMAIL_THEME,
              ],
            },
          },
          {
            path: RouteList.SETTINGS.BRANDING.EMAIL.EMAIL_DESIGNS.PATH,
            name: RouteList.SETTINGS.BRANDING.EMAIL.EMAIL_DESIGNS.NAME,
            component: EmailDesignList,
            meta: {
              feature: [FeatureEnum.SETTINGS_BRANDING_EMAILS, FeatureEnum.SETTINGS_BRANDING],
              permission: [
                AbilityEnum.SETTING_BRANDING_EMAIL,
                AbilityEnum.SETTING_BRANDING_EMAIL_DESIGN,
              ],
            },
          },
          {
            path: RouteList.SETTINGS.BRANDING.EMAIL.CONFIG.PATH,
            name: RouteList.SETTINGS.BRANDING.EMAIL.CONFIG.NAME,
            component: EmailThemeConfig,
            meta: {
              feature: [FeatureEnum.SETTINGS_BRANDING_EMAILS, FeatureEnum.SETTINGS_BRANDING],
              permission: [
                AbilityEnum.SETTING_BRANDING_EMAIL,
                AbilityEnum.SETTING_BRANDING_EMAIL_THEME,
              ],
            },
          },
        ],
      },
      {
        path: RouteList.SETTINGS.BRANDING.PHONE_NUMBERS.INDEX.PATH,
        name: RouteList.SETTINGS.BRANDING.PHONE_NUMBERS.INDEX.NAME,
        component: SettingsPhoneNumbers.Index,
        meta: {
          feature: [FeatureEnum.SETTINGS_BRANDING_PHONE_NUMBER, FeatureEnum.SETTINGS_BRANDING],
          permission: [
            AbilityEnum.SETTING_BRANDING_PHONE_NUMBERS_PHONE_NUMBERS,
            AbilityEnum.SETTING_BRANDING_PHONE_NUMBERS,
            AbilityEnum.SETTING_BRANDING,
          ],
        },
        redirect: {
          name: RouteList.SETTINGS.BRANDING.PHONE_NUMBERS.PHONE_NUMBERS.NAME,
        },
        children: [
          {
            path: RouteList.SETTINGS.BRANDING.PHONE_NUMBERS.PHONE_NUMBERS.PATH,
            name: RouteList.SETTINGS.BRANDING.PHONE_NUMBERS.PHONE_NUMBERS.NAME,
            component: SettingsPhoneNumbers.PhoneNumbers,
            meta: {
              feature: [FeatureEnum.SETTINGS_BRANDING_PHONE_NUMBER, FeatureEnum.SETTINGS_BRANDING],
              permission: [
                AbilityEnum.SETTING_BRANDING_PHONE_NUMBERS_PHONE_NUMBERS,
                AbilityEnum.SETTING_BRANDING_PHONE_NUMBERS,
                AbilityEnum.SETTING_BRANDING,
              ],
            },
          },
          {
            path: RouteList.SETTINGS.BRANDING.PHONE_NUMBERS.COMPLIANCE.PATH,
            name: RouteList.SETTINGS.BRANDING.PHONE_NUMBERS.COMPLIANCE.NAME,
            component: SettingsPhoneNumbers.Compliance,
            meta: {
              feature: [FeatureEnum.SETTINGS_BRANDING_PHONE_NUMBER, FeatureEnum.SETTINGS_BRANDING],
              permission: [
                AbilityEnum.SETTING_BRANDING_PHONE_NUMBERS_COMPLIANCE,
                AbilityEnum.SETTING_BRANDING_PHONE_NUMBERS,
                AbilityEnum.SETTING_BRANDING,
              ],
            },
          },
        ],
      },
      {
        path: RouteList.SETTINGS.BRANDING.PHONE_NUMBERS.FORM.PATH,
        name: RouteList.SETTINGS.BRANDING.PHONE_NUMBERS.FORM.NAME,
        component: ComplianceApplication,
        meta: {
          feature: [FeatureEnum.SETTINGS_BRANDING_PHONE_NUMBER, FeatureEnum.SETTINGS_BRANDING],
          permission: [
            AbilityEnum.SETTING_BRANDING_PHONE_NUMBERS_COMPLIANCE_APPLY,
            AbilityEnum.SETTING_BRANDING_PHONE_NUMBERS_COMPLIANCE,
            AbilityEnum.SETTING_BRANDING_PHONE_NUMBERS,
            AbilityEnum.SETTING_BRANDING,
          ],
        },
      },
      {
        path: RouteList.SETTINGS.BRANDING.PHONE_NUMBERS.SEARCH_NUMBERS.PATH,
        name: RouteList.SETTINGS.BRANDING.PHONE_NUMBERS.SEARCH_NUMBERS.NAME,
        component: SearchNumbers,
        meta: {
          feature: [FeatureEnum.SETTINGS_BRANDING_PHONE_NUMBER, FeatureEnum.SETTINGS_BRANDING],
          permission: [
            AbilityEnum.SETTING_BRANDING_PHONE_NUMBERS_PHONE_NUMBERS,
            AbilityEnum.SETTING_BRANDING_PHONE_NUMBERS,
            AbilityEnum.SETTING_BRANDING,
          ],
        },
      },
      {
        path: RouteList.SETTINGS.BRANDING.DOMAINS.INDEX.PATH,
        name: RouteList.SETTINGS.BRANDING.DOMAINS.INDEX.NAME,
        component: CustomDomains.Index,
        meta: {
          feature: [FeatureEnum.SETTINGS_BRANDING_DOMAIN, FeatureEnum.SETTINGS_BRANDING],
          permission: [AbilityEnum.SETTING_BRANDING_DOMAINS, AbilityEnum.SETTING_BRANDING],
        },
      },
      {
        path: RouteList.SETTINGS.BRANDING.DOMAINS.DOMAIN.PATH,
        name: RouteList.SETTINGS.BRANDING.DOMAINS.DOMAIN.NAME,
        component: CustomDomains.Profile,
        meta: {
          feature: [FeatureEnum.SETTINGS_BRANDING_DOMAIN, FeatureEnum.SETTINGS_BRANDING],
          permission: [AbilityEnum.SETTING_BRANDING_DOMAINS, AbilityEnum.SETTING_BRANDING],
        },
      },
    ],
  },
  {
    path: RouteList.SETTINGS.ACCESS.INDEX.PATH,
    name: RouteList.SETTINGS.ACCESS.INDEX.NAME,
    components: {
      default: DashboardMain,
      SideBar: SubSideBar,
      NavBar,
    },
    props: {
      SideBar: {
        menuCode: MenuCode.Settings,
        title: 'Settings',
      },
    },
    meta: {
      requiresAuth: true,
      requiresSubscription: true,
      feature: FeatureEnum.SETTINGS_ACCESS,
      permission: AbilityEnum.SETTING_ACCESS,
    },
    redirect: {
      name: RouteList.SETTINGS.ACCESS.USERS.INDEX.NAME,
    },
    children: [
      {
        path: RouteList.SETTINGS.ACCESS.USERS.INDEX.PATH,
        name: RouteList.SETTINGS.ACCESS.USERS.INDEX.NAME,
        components: {
          default: DashboardMain,
          SideBar: SubSideBar,
          NavBar,
        },
        props: {
          SideBar: {
            menuCode: MenuCode.Settings,
            title: 'Settings',
          },
        },
        meta: {
          requiresAuth: true,
          requiresSubscription: true,
          feature: [FeatureEnum.SETTINGS_ACCESS_USERS, FeatureEnum.SETTINGS_ACCESS],
          permission: AbilityEnum.SETTING_ACCESS_USER,
        },
        redirect: {
          name: RouteList.SETTINGS.ACCESS.USERS.ACTIVE.NAME,
        },
        children: [
          {
            path: RouteList.SETTINGS.ACCESS.USERS.ACTIVE.PATH,
            name: RouteList.SETTINGS.ACCESS.USERS.ACTIVE.NAME,
            component: UserList,
            meta: {
              feature: [FeatureEnum.SETTINGS_ACCESS_USERS, FeatureEnum.SETTINGS_ACCESS],
              permission: [
                AbilityEnum.SETTING_ACCESS_USER_ACTIVE,
                AbilityEnum.SETTING_ACCESS_USER,
                AbilityEnum.SETTING_ACCESS,
              ],
            },
          },
          {
            path: RouteList.SETTINGS.ACCESS.USERS.USER.PATH,
            name: RouteList.SETTINGS.ACCESS.USERS.USER.NAME,
            component: UserSingle,
            meta: {
              feature: [FeatureEnum.SETTINGS_ACCESS_USERS, FeatureEnum.SETTINGS_ACCESS],
              permission: [
                AbilityEnum.SETTING_ACCESS_USER_ACTIVE_VIEW,
                AbilityEnum.SETTING_ACCESS_USER,
                AbilityEnum.SETTING_ACCESS,
              ],
            },
          },
          {
            path: RouteList.SETTINGS.ACCESS.USERS.INVITATIONS.PATH,
            name: RouteList.SETTINGS.ACCESS.USERS.INVITATIONS.NAME,
            component: InvitationList,
            meta: {
              feature: [FeatureEnum.SETTINGS_ACCESS_USERS, FeatureEnum.SETTINGS_ACCESS],
              permission: [
                AbilityEnum.SETTING_ACCESS_USER_INVITATION,
                AbilityEnum.SETTING_ACCESS_USER,
                AbilityEnum.SETTING_ACCESS,
              ],
            },
          },
        ],
      },
      {
        path: RouteList.SETTINGS.ACCESS.ROLES.PATH,
        name: RouteList.SETTINGS.ACCESS.ROLES.NAME,
        component: RoleList,
        meta: {
          feature: [FeatureEnum.SETTINGS_ACCESS_ROLES, FeatureEnum.SETTINGS_ACCESS],
          permission: [AbilityEnum.SETTING_ACCESS_ROLE, AbilityEnum.SETTING_ACCESS],
        },
      },
    ],
  },
]
