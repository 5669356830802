import { computed } from 'vue'
import { useBusiness } from '@/store/Business'
import PermissionDto from '@/dto/Auth/PermissionDto'
import { useAuth } from '@/store/Auth'
import { AbilityEnum } from '@/enums/AbilityEnum'
import { BusinessUserResponseDto } from '@/dto/Business/User/Response'

export const permissionExistsInPermissions = (
  requiredPermission: string | string[],
  admin = false,
  isUserAdmin = false,
  existingPermissionNames: string[] = [],
) => {
  if (!Array.isArray(requiredPermission)) {
    requiredPermission = [requiredPermission]
  }

  if (admin) {
    return (
      existingPermissionNames.includes('*') ||
      requiredPermission.every((rp) => existingPermissionNames.includes(rp))
    )
  }

  return isUserAdmin || requiredPermission.every((rp) => existingPermissionNames.includes(rp))
}

export default () => {
  const businessState = useBusiness()
  const authState = useAuth()

  const abilities = computed(() =>
    authState.getters.abilities.concat(businessState.getters.abilities),
  )

  const permissions = computed(() => abilities.value.map((ability: PermissionDto) => ability.name))

  const currentUserRolePosition = computed(() =>
    Math.min(...businessState.getters.businessUserRoles.map((role) => role.position)),
  )

  const currentUserRoleLevel = computed(() =>
    Math.max(...businessState.getters.businessUserRoles.map((role) => role.level)),
  )

  const permissionExists = (requiredPermission: string | string[], admin = false) =>
    permissionExistsInPermissions(
      requiredPermission,
      admin,
      authState.getters.user?.isAdmin,
      permissions.value,
    )

  const hasAllLocationAccess = computed<boolean>(() => {
    // if user is admin or has ability for all locations access
    if (authState.getters.user?.isAdmin) {
      return true
    }

    return !!businessState.getters.abilities.find(
      (l) => l.entity_id === null && l.name === AbilityEnum.LOCATION_ACCESS,
    )
  })

  const accessibleLocationGroups = computed<string[]>(() => {
    const groupAbilities = abilities.value.filter(
      (ability: PermissionDto) => ability.name === AbilityEnum.LOCATION_GROUP_ACCESS,
    )

    // user has access to all location groups - return all groups
    if (groupAbilities.some((ability: PermissionDto) => ability.entity_id === null)) {
      return businessState.getters.locations
        .filter((location) => location.group !== null)
        .map((location) => location.group!.id)
    }

    // user has access to specific location groups - return specific groups
    return groupAbilities
      .filter((ability) => ability.entity_id !== null)
      .map((ability) => ability.entity_id as string)
  })

  const hasGroupLocationAccess = computed<boolean>(() => !!accessibleLocationGroups.value.length)

  // Compare current user's and given user's role hierarchy
  const isUserHigherLevel = (user: BusinessUserResponseDto): boolean => {
    // Edge-case, when user has no role, means it has custom abilities.
    if (!user.roles.length) {
      return permissionExists(AbilityEnum.SETTING_ACCESS_USER_GRANT_CUSTOM_ABILITIES)
    }

    // Role levels go in ascending order: 1 - business, 2 - admin
    // Role positions go in descending order: 0 - highest, 1 - second highest, etc.
    const userRolePosition = Math.min(...user.roles.map((role) => role.position))
    const userRoleLevel = Math.max(...user.roles.map((role) => role.level))

    return (
      currentUserRoleLevel.value > userRoleLevel ||
      (currentUserRoleLevel.value === userRoleLevel &&
        currentUserRolePosition.value <= userRolePosition)
    )
  }

  return {
    permissions,
    permissionExists,
    hasAllLocationAccess,
    accessibleLocationGroups,
    hasGroupLocationAccess,
    isUserHigherLevel,
  }
}
