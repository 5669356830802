import {
  ref,
  Ref,
  computed,
  defineAsyncComponent,
  AsyncComponentLoader,
  Component,
  h,
  defineComponent,
  onMounted,
  onUnmounted,
} from 'vue'

type ComponentResolver = (component: Component) => void

const isReloadModalVisible: Ref<boolean> = ref(false)

export const useReloadModal = () => {
  return {
    isReloadModalVisible: computed(() => isReloadModalVisible.value),
  }
}

const handleComponentError = (
  error: Error,
  retry: () => void,
  fail: () => void,
  attempts: number,
) => {
  if (attempts < 3) {
    setTimeout(() => retry(), 200)
  } else {
    isReloadModalVisible.value = true
  }
}

export const asyncComponentWrapper = (component: AsyncComponentLoader) =>
  defineAsyncComponent({
    loader: component,
    onError: handleComponentError,
  })

// Lazy load component example from https://mokkapps.de/blog/lazy-load-vue-component-when-it-becomes-visible#intersection-observer-api
// Adjusted a little bit for our needs
export const lazyLoadComponentIfVisible = ({
  componentLoader,
}: {
  componentLoader: AsyncComponentLoader
}) => {
  let resolveComponent: ComponentResolver

  return defineAsyncComponent({
    loader: () => {
      return new Promise((resolve) => {
        resolveComponent = resolve as ComponentResolver
      })
    },
    loadingComponent: defineComponent({
      setup() {
        const elRef = ref<Element>()

        async function loadComponent() {
          const component = await componentLoader()
          resolveComponent(component)
        }

        onMounted(async () => {
          if (!('IntersectionObserver' in window)) {
            await loadComponent()
            return
          }

          const observer = new IntersectionObserver(async (entries) => {
            if (!entries[0].isIntersecting) {
              return
            }

            if (elRef.value) observer.unobserve(elRef.value)
            await loadComponent()
          })

          if (elRef.value) observer.observe(elRef.value)

          onUnmounted(() => {
            observer.disconnect()
          })
        })

        return () => {
          return h('div', { ref: elRef })
        }
      },
    }),
    onError: handleComponentError,
  })
}
