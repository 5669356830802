<template>
  <div
    class="d-flex w-100"
    :class="{
      'bg-dark': isBgDark,
      'text-dark': !isBgDark,
      'text-light': isBgDark,
    }"
  >
    <div class="container d-flex mb-0 w-100">
      <div class="col-12 m-4">
        <div class="d-flex align-items-center mb-3">
          <BaseBackButton :back-to-route="RouteList.UI.INDEX.NAME" />
          <h1 class="mb-0">Iconography</h1>
        </div>

        <div class="d-flex gap-2 mb-4">
          <button class="btn btn-outline-primary" @click="isBgDark = !isBgDark">Toggle Mode</button>
          <button class="btn btn-outline-primary" @click="isSorted = !isSorted">Toggle Sort</button>
        </div>

        <div class="d-flex flex-wrap gap-2 align-items-start">
          <template v-for="icon in displaySymbols" :key="icon.id">
            <span
              :title="icon.id"
              class="p-2 border-primary"
              style="border: 1px solid; border-radius: 4px"
            >
              <ATooltip :title="icon.id">
                <BasePortalIcon :icon="icon.id" :width="icon.width" :height="icon.height" />
              </ATooltip>
            </span>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed, ref } from 'vue'
import { RouteList } from '@/routes/RouteList.js'
import BasePortalIcon from '@/components/BaseComponents/BasePortalIcon.vue'
import { asyncComponentWrapper } from '@/composables/useReloadModal.js'
import PortalSvg from '@/assets/images/portal.svg?raw'

const ATooltip = asyncComponentWrapper(() => import('ant-design-vue/es/tooltip'))

const regexId = /<symbol[^>]*id="([^"]+)"/g
const regexViewBox = /viewBox="([^"]+)"/g
let match
const symbols: { id: string; width?: string; height?: string }[] = []
while ((match = regexId.exec(PortalSvg)) !== null) {
  const viewBox = regexViewBox.exec(PortalSvg)
  let width = undefined
  let height = undefined
  if (viewBox !== null) {
    width = String(viewBox[1]).split(' ')[2] + 'px'
    height = String(viewBox[1]).split(' ')[3] + 'px'
  }
  symbols.push({
    id: match[1],
    ...{ width, height },
  })
}

const sorted = symbols
  .map((s) => s)
  .sort((a, b) => {
    if (a.id < b.id) return -1
    if (a.id > b.id) return 1
    return 0
  })

const isBgDark = ref(false)
const isSorted = ref(false)

const displaySymbols = computed(() => {
  if (isSorted.value) {
    return sorted
  }

  return symbols
})
</script>

<style lang="scss" scoped>
svg {
  max-height: 64px;
  max-width: 200px;
}
</style>
