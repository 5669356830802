<template>
  <div class="input-group" :class="{ 'allow-clear': !!value, [customClass]: true }">
    <input
      v-model.lazy="value"
      class="form-control border-end-0"
      :placeholder="t(placeholder)"
      :disabled="disabled"
      type="text"
      @change="emit('change', value)"
    />
    <span
      class="input-group-text search-icon-size text-muted"
      :class="{ 'input-group-text-disabled': disabled }"
    >
      <BasePortalIcon
        v-if="!!value && searchConfig.displayClearIcon"
        icon="plus"
        class="rotate--45 text-danger me-2 cursor-pointer"
        @click="clearValue"
      />
      <BasePortalIcon
        v-if="searchConfig.displaySearchIcon"
        icon="search"
        class="cursor-pointer"
        @click="search"
      />
    </span>
  </div>
</template>

<script lang="ts" setup>
import { computed, ref, Ref, toRef, watch } from 'vue'
import { useRoute } from 'vue-router'
import { useI18n } from 'vue-i18n'

const { t } = useI18n()
const currentRoute = useRoute()

const value: Ref<string> = ref('')

const props = defineProps({
  modelValue: {
    type: String,
    default: '',
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  placeholder: {
    type: String,
    default: 'Quick Search...',
  },
  config: {
    type: Object,
    default: () => ({}),
  },
  customClass: {
    type: String,
    default: 'transition-width',
  },
  hideValue: {
    type: Boolean,
  },
})

const emit = defineEmits<{
  (e: 'change', value: string): void
  (e: 'update:modelValue', value: string): void
}>()

const modelValue = toRef(props, 'modelValue')

const searchConfig = computed(() => {
  const _config = {
    displaySearchIcon: true,
    displayClearIcon: true,
  }
  return Object.assign(_config, props.config)
})

const search = () => {
  if (props.disabled) return

  emit('change', value.value)
}

const clearValue = () => {
  if (props.disabled) return

  value.value = ''
  emit('change', value.value)
}

watch(value, (val) => {
  emit('update:modelValue', val)
})

watch(
  modelValue,
  (val) => {
    value.value = val
  },
  {
    immediate: true,
  },
)

const setValue = () => {
  if (props.hideValue) return

  value.value = currentRoute.query.search ? String(currentRoute.query.search) : ''
}

setValue()

watch(
  () => currentRoute.query,
  () => setValue(),
)
</script>

<style lang="scss" scoped>
@use '@/assets/scss/_variables' as vars;

.search-icon-size {
  font-size: 1rem;
  transition: vars.$input-transition;
}

.form-control:focus + .search-icon-size {
  border-color: vars.$primary;
}

.transition-width {
  transition: width 0.8s;
  width: 210px;

  &.allow-clear {
    width: 250px;
  }
}

.input-group-text-disabled {
  background-color: vars.$disabled-input;
  opacity: 1;

  & > * {
    cursor: not-allowed !important;
  }
}
</style>
