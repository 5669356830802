<template>
  <BaseFormFieldLayout
    :id="id"
    :label="label"
    :note="note"
    :error-message="errorMessage"
    :field-class="fieldClass"
    :label-class="labelClass"
  >
    <a-select
      :id="id"
      v-model:value="model"
      v-bind="$attrs"
      :class="{ 'is-invalid': errorMessage, 'w-100': !$attrs.class }"
      option-filter-prop="label"
      option-label-prop="label"
      size="large"
      @change="$attrs.onChange"
    />
  </BaseFormFieldLayout>
</template>

<script lang="ts" setup>
import { asyncComponentWrapper } from '@/composables/useReloadModal'
const ASelect = asyncComponentWrapper(() => import('ant-design-vue/es/select'))

const model = defineModel<string | string[] | number | number[]>()

defineProps({
  id: {
    type: [String || Number],
    required: true,
  },
  label: {
    type: String,
    default: '',
  },
  note: {
    type: String,
    default: '',
  },
  errorMessage: {
    type: String,
    default: '',
  },
  fieldClass: {
    type: String,
    default: '',
  },
  labelClass: {
    type: String,
    default: '',
  },
})
</script>
