<template>
  <template v-if="hasPermission">
    <slot></slot>
  </template>
  <ATooltip v-else-if="!hide" :title="t('You don\'t have permission.')" placement="bottom">
    <div class="tooltip-disabled" :class="wrapperClass">
      <slot />
    </div>
  </ATooltip>
</template>

<script lang="ts" setup>
import { computed, PropType } from 'vue'
import { useI18n } from 'vue-i18n'
import usePermissions from '@/composables/usePermissions'
import { asyncComponentWrapper } from '@/composables/useReloadModal'
import useFeatures from '@/composables/useFeatures'

const ATooltip = asyncComponentWrapper(() => import('ant-design-vue/es/tooltip'))

const props = defineProps({
  permission: {
    type: [String, Array] as PropType<string | string[]>,
    required: true,
  },
  wrapperClass: {
    type: String,
    default: 'd-grid',
  },
  hide: {
    type: Boolean,
    default: false,
  },
  admin: {
    type: Boolean,
    default: false,
  },
})

const { t } = useI18n()
const { permissionExists } = usePermissions()
const { isLmsFeature } = useFeatures()

const hasPermission = computed(() => {
  const permissions = (
    typeof props.permission === 'string' ? [props.permission] : props.permission
  ).map((p) => {
    if (p.startsWith('lms') || p.startsWith('ems')) {
      return (isLmsFeature.value ? 'lms' : 'ems') + p.slice(3)
    }

    return p
  })

  return permissionExists(permissions, props.admin)
})
</script>

<style lang="scss">
.tooltip-disabled {
  cursor: not-allowed !important;

  a:not(.btn) {
    color: #adb5bd;
    background-color: transparent;
    pointer-events: none;
  }

  .btn,
  button {
    opacity: 0.65;
    pointer-events: none;
  }
}
</style>
